import type { HandleClientError } from '@sveltejs/kit'
import * as Sentry from '@sentry/sveltekit'

import { dev } from '$app/environment'
import { PUBLIC_SENTRY_DSN } from '$env/static/public'

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1.0,
	// For instance, initialize Session Replay:
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [new Sentry.Replay()],
})

const myErrorHandler = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event)
}

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)

// export const handleError: HandleClientError = ({ error, event }) => {
// 	// example integration with https://sentry.io/
// 	console.error(error)
// 	if (!dev) {
// 		Sentry.captureException(error, { contexts: { sveltekit: { event } } })
// 	}

// 	return {
// 		message: error.message,
// 		code: error.code ?? 'UNKNOWN',
// 	}
// }
