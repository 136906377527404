import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [13,[2],[3]],
		"/(app)/account/billing": [14,[2,4],[3]],
		"/(app)/account/integrations": [15,[2,4],[3]],
		"/(app)/account/notifications": [16,[2,4],[3]],
		"/(app)/account/organization": [17,[2,4],[3]],
		"/(app)/account/profile": [18,[2,4],[3]],
		"/(app)/account/update-email": [19,[2,4,5],[3]],
		"/(app)/account/update-password": [~20,[2,4,6],[3]],
		"/(app)/admin": [~21,[2],[3]],
		"/(app)/admin/users": [22,[2],[3]],
		"/(app)/admin/users/[id]": [23,[2],[3]],
		"/(app)/calendar": [24,[2],[3]],
		"/(app)/courses": [25,[2,7],[3]],
		"/(app)/courses/mux": [26,[2,7],[3]],
		"/(app)/feed": [~27,[2],[3]],
		"/(auth)/forgot-password": [54,[10]],
		"/(app)/kanban": [28,[2],[3]],
		"/(auth)/login": [55,[10]],
		"/(app)/logout": [~29,[2],[3]],
		"/(app)/map": [30,[2],[3]],
		"/(app)/messages": [31,[2],[3]],
		"/(app)/onboarding": [32,[2,8],[3]],
		"/(app)/playground": [33,[2,9],[3]],
		"/(app)/playground/charts": [34,[2,9],[3]],
		"/(app)/playground/code": [35,[2,9],[3]],
		"/(app)/playground/documents": [36,[2,9],[3]],
		"/(app)/playground/form": [~37,[2,9],[3]],
		"/(app)/playground/jobs/new": [39,[2,9],[3]],
		"/(app)/playground/jobs/[id]/edit": [38,[2,9],[3]],
		"/(app)/playground/super-form": [~40,[2,9],[3]],
		"/(app)/playground/super-form/users": [~41,[2,9],[3]],
		"/(app)/playground/test": [42,[2,9],[3]],
		"/(app)/pricing": [~43,[2],[3]],
		"/(app)/products": [44,[2],[3]],
		"/(app)/products/id": [46,[2],[3]],
		"/(app)/products/[id]": [45,[2],[3]],
		"/(app)/projects": [~47,[2],[3]],
		"/(app)/projects/[id]": [48,[2],[3]],
		"/(app)/realtime": [49,[2],[3]],
		"/(app)/realtime/presence": [50,[2],[3]],
		"/(auth)/register": [56,[10]],
		"/(app)/stripe/order": [51,[2],[3]],
		"/(app)/stripe/payment": [52,[2],[3]],
		"/(app)/stripe/success": [~53,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';